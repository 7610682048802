.footer {
  background: var(--theme-body-color);
  grid-area: footer;
  padding: 16px 64px 0;
  margin: auto 0 0;
}

.footerBottom {
  border-top: 1px solid var(--theme-border-color);
  padding: 24px 0;
  text-align: center;
}

.copyright {
  color: var(--theme-icon-color);
}
