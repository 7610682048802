.container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 80px;
    @include t {
      padding: 0 40px;
    }
    @include m {
      padding: 0 32px;
    }
  }