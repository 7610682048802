$gaps: 4, 8, 12, 16, 24, 32, 40, 48, 64;

.flex {
  display: flex;
  flex-wrap: nowrap;

  &.inline {
    display: inline-flex;
  }

  &.flow-row {
    flex-direction: column;
  }

  &.flow-col {
    flex-direction: row;
  }

  &.wrap {
    flex-wrap: wrap;
  }
  
  &.flex-grow,
  > .flex-grow {
    flex-grow: 1;
  }

  > .flex-shrink {
    flex-shrink: 1;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  > .align-self-start {
    align-self: flex-start;
  }

  > .align-self-center {
    align-self: center;
  }

  > .align-self-end {
    align-self: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  > .justify-self-start {
    justify-self: flex-start;
  }

  > .justify-self-center {
    justify-self: center;
  }

  > .justify-self-end {
    justify-self: end;
  }

  @each $gap in $gaps {
    &.row-gap-#{$gap} {
      row-gap: #{$gap}px;
    }

    &.col-gap-#{$gap} {
      column-gap: #{$gap}px;
    }
  }
}