@import 'antd.css';
@import 'flex';
@import 'grid';
@import 'box';
@import 'utils';
@import 'text';

html {
  block-size: 100%;
  inline-size: 100%;
}

body {
  background: var(--theme-body-color);
  color: var(--theme-primary-color);
  font-family: Kanit, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-block-size: 100%;
  min-inline-size: 100%;
}

:root {

  --font-size-h1: 40px;
  --font-size-h2: 32px;
  --font-size-h3: 24px;
  --font-size-p1: 20px;
  --font-size-p2: 16px;
  --font-size-lb1: 14px;
  --font-size-lb2: 12px;
  --font-size-sm: 12px;

  --theme-body-color: #f8f8f9; // grey50
  --theme-card-color: #fff;
  --theme-overlay-color: #fff;
  --theme-mask-color: rgba(19, 32, 43, 0.8);
  --theme-border-color: #e3e4e6; // grey100
  --theme-border-color-rgb: 227, 228, 230;
  --theme-icon-color: #aaafb3; // grey300
  --theme-icon-hover-color: #060a0d; // grey900

  // --theme-primary-color: #060a0d; // grey900
  --theme-primary-color: #725AE8; // grey900
  --theme-secondary-color: #717980; // grey500
  --theme-secondary-color-rgb: 113, 121, 128; // grey500
  --theme-default-color: #aaafb3; // grey300

  --theme-card-shadow: 0 0 0.5px rgba(6, 10, 13, 0.4), 0 8px 16px rgba(113, 121, 128, 0.08);
  --theme-modal-shadow: 0 0 0.5px rgba(6, 10, 13, 0.4), 0 16px 32px rgba(19, 32, 43, 0.4);
  --theme-overlay-shadow: 0 0 0.5px rgba(0, 0, 0, 0.4), 0 32px 64px rgba(113, 121, 128, 0.32);

  --theme-input-background: #fff;
  --theme-input-placeholder: #aaafb3;

  --theme-red-color: #ff4339; // red500
  --theme-red-color-rgb: 255, 67, 57; // red500

  --theme-green-color: #00d395; // green500
  --theme-green-color-rgb: 0, 211, 149; // green500

  --theme-green700-color: #007f59;

  --theme-blue-color: #4f6ae6; // blue500
  --theme-blue-color-rgb: 79, 106, 230; // blue500
  --theme-blue400-color: #7288ea;

  --theme-yellow-color: #cca74d; // yellow600
  --theme-yellow-color-rgb: 204, 167, 77; // yellow600

  --theme-purple-color: #a26ee3; // purple500
  --theme-purple-color-rgb: 162, 110, 227; // purple500
  --theme-purple700-color: #614288;

  --theme-grey900-color: #060a0d;

  --theme-white-color: #fff;

  --theme-primary-navy-color: #725AE8;

  --theme-primary-blue-color: #009cdf; // primary blue600
  --theme-primary-blue-color-rgb: 0, 156, 223; // primary blue600

  --horizontal-padding: 64px;
}

@media (max-width: 1024px) {
  :root {
    --font-size-h1: 24px;
    --font-size-h2: 18px;
    --font-size-h3: 14px;
    --font-size-p1: 12px;
    --font-size-p2: 11px;
    --font-size-lb1: 11px;
    --font-size-lb2: 9px;
    --font-size-sm: 9px;
  }
}

@media (max-width: 768px) {
  :root {
    --horizontal-padding: 24px;
  }
}

button {
  cursor: pointer;
  font-family: inherit;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}


