// colors
// $p1: #3772ff;
$p1: #725ae8;
$p2: #9757d7;
$p3: #ff6838;
$p4: #58bd7d;
$s1: #4bc9f0;
$s2: #e4d7cf;
$s3: #ffd166;
$s4: #cdb4db;
$s5: #fff8dd;
$s6: #cbf0b9;
$white: #ffffff;
$warning: #f7c602;
$green: #319400;


$n1: #141416;
$n2: #23262f;
$n3: #353945;
$n4: #777e90;
$n5: #b1b5c3;
$n6: #e6e8ec;
$n7: #f4f5f6;
$n8: #fcfcfd;
