.block {
  display: block;
}

.inline {
  display: inline-block;
}

.relative {
  position: relative;
}

$margins: 4, 8, 12, 16, 24, 32, 40, 48, 64;

@each $margin in $margins {
  .mr-#{$margin}.mr-#{$margin} {
    margin-right: #{$margin}px;
  }
  .mb-#{$margin}.mb-#{$margin} {
    margin-bottom: #{$margin}px;
  }
}

@media (max-width: 768px) {
  @each $margin in $margins {
    .sm-mr-#{$margin}.sm-mr-#{$margin} {
      margin-right: #{$margin}px;
    }
    .sm-mb-#{$margin}.sm-mb-#{$margin} {
      margin-bottom: #{$margin}px;
    }
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mh-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

$paddings: 0, 4, 8, 12, 16, 24, 32, 48, 64;

@each $padding in $paddings {
  .p-#{$padding}.p-#{$padding} {
    padding: #{$padding}px;
  }
  .pv-#{$padding}.pv-#{$padding} {
    padding-top: #{$padding}px;
    padding-bottom: #{$padding}px;
  }
  .ph-#{$padding}.ph-#{$padding} {
    padding-left: #{$padding}px;
    padding-right: #{$padding}px;
  }
  .pb-#{$padding}.pb-#{$padding} {
    padding-bottom: #{$padding}px;
  }
}

@media (max-width: 768px) {
  @each $padding in $paddings {
    .sm-p-#{$padding}.sm-p-#{$padding} {
      padding: #{$padding}px;
    }
    .sm-pv-#{$padding}.sm-pv-#{$padding} {
      padding-top: #{$padding}px;
      padding-bottom: #{$padding}px;
    }
    .sm-ph-#{$padding}.sm-ph-#{$padding} {
      padding-left: #{$padding}px;
      padding-right: #{$padding}px;
    }
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.h-divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--theme-border-color);
}

.v-divider {
  width: 1px;
  height: auto;
  border-left: 1px solid var(--theme-border-color);
}