@import '../../styles/helpers.scss';

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
}
.item {
  @include button-2;
  color: $n2;
  @include dark {
    color: $n8;
  }
  &:not(:last-child) {
    margin-right: 16px;
    &:after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 9px;
      margin-left: 16px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777E90'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 100% auto;
    }
  }
  &:not(:last-child):not(:nth-last-child(2)) {
    @include m {
      display: none;
    }
  }
}
.link {
  color: $n4;
  transition: color 0.2s;
  &:hover {
    color: $p1;
  }
}
