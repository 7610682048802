[class^="section"] {
    margin-bottom: 136px;
    @include d {
      margin-bottom: 112px;
    }
    @include m {
      margin-bottom: 64px;
    }
  }
  .section-bg {
    padding: 136px 0;
    background: $n7;
    @include d {
      padding: 112px 0;
    }
    @include m {
      padding: 64px 0;
    }
    @include dark-common {
      background: #18191D;
    }
  }
  .section-mb0 {
    margin-bottom: 0;
    @include d {
      margin-bottom: 0;
    }
    @include m {
      margin-bottom: 0;
    }
  }
  .section-padding {
    padding: 136px 0;
    @include d {
      padding: 112px 0;
    }
    @include m {
      padding: 64px 0;
    }
  }