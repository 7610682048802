@import 'src/styles/antd/text';

.content-container {
  --content-container-v-padding: 40px;

  padding: var(--content-container-v-padding) var(--horizontal-padding);
  width: 100%;

  @media (max-width: 768px) {
    --content-container-v-padding: 24px;
  }
}

.content-container-fix {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}

.container-limit {
  max-width: 1070px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container-fit {
  max-width: 640px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.tab-cards {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(200px, max-content));

  @media (max-width: 768px) {
    gap: 8px;
    // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.tab-card {
  background-color: var(--theme-card-color);
  border: 1px solid var(--theme-border-color);
  border-radius: 4px;
  box-shadow: var(--theme-card-shadow);
  display: flex;
  align-items: center;
  padding: 15px 16px;
  text-align: left;

  &[disabled] {
    border-color: var(--theme-body-color);
    filter: grayscale(1);
    cursor: not-allowed;

    [data-theme='dark'] & {
      border-color: var(--theme-body-color);
    }
  }
}

.tab-card.active {
  border-color: var(--theme-red-color);
}

.card {
  background-color: var(--theme-card-color);
  border-radius: 4px;
  box-shadow: var(--theme-card-shadow);

  .card-row:not(:last-of-type) {
    border-bottom: 1px solid var(--theme-border-color);
  }
}

.card-header {
  border-bottom: 1px solid var(--theme-border-color);
  padding: 24px;
  overflow-x: auto;
}

.card-footer {
  border-top: 1px solid var(--theme-border-color);
  padding: 24px;
}

.css-grid {
  --gap: 1rem;
  // --sm-gap: 0.5rem;
  --min: 15ch;
  // --sm-min: 10ch;

  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(var(--min), 1fr));

  @media (max-width: 768px) {
    grid-gap: var(--sm-gap, var(--gap));
    grid-template-columns: repeat(auto-fit, minmax(var(--sm-min, --min), 1fr));
  }
}

.flexbox-grid {
  --gap: 1rem;
  // --sm-gap: 0.5rem;
  --min: 10ch;
  // --sm-min: 5ch;

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  @media (max-width: 768px) {
    gap: var(--sm-gap, var(--gap));
  }
}

.flexbox-grid > * {
  flex: 1 1 var(--min);

  @media (max-width: 768px) {
    gap: var(--sm-min, var(--min)) !important;
  }
}

.flexbox-list {
  --gap: 16px;
  // --sm-gap: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  @media (max-width: 768px) {
    gap: var(--sm-gap, var(--gap));
  }
}

.color-primary {
  color: var(--theme-primary-color);
}

.color-secondary {
  color: var(--theme-secondary-color);
}

.color-border {
  color: var(--theme-border-color);
}

.color-red {
  color: var(--theme-red-color);
}

.color-green {
  color: var(--theme-green-color);
}

.color-icon {
  color: var(--theme-icon-color);
}

.hidden-mobile {
  @media (max-width: 480px) {
    display: none !important;
  }
}

.hidden-tablet {
  @media (min-width: 481px) and (max-width: 767px) {
    display: none !important;
  }
}

.hidden-desktop {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.middle-dot {
  display: flex;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--dot-color, currentColor);
    border-radius: 50%;
  }
}