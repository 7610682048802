[class^="category"] {
    display: inline-block;
    padding: 8px 8px 6px;
    background: $n2;
    border-radius: 4px;
    @include hairline-2;
    color: $n8;
  }
  .category-blue {
    background: $p1;
  }
  .category-purple {
    background: $p2;
  }
  .category-green {
    background: $p4;
  }
  .category-red {
    background: $p3;
  }
  .category-gray {
    background: $n5;
    @include dark-common {
      background: $n3;
      color: $n8;
    }
  }
  .category-stroke-green {
    background: none;
    box-shadow: inset 0 0 0 2px $p4;
    color: $p4;
  }