@import "../../styles/helpers.scss"; 

.header {
  position: relative;
  z-index: 10;
  box-shadow: inset 0 -1px 0 $n6;
  @include m {
    padding: 32px 0 24px;
    box-shadow: none;
  }
  @include dark {
    box-shadow: inset 0 -1px 0 $n2;
    @include m {
      box-shadow: none;
    }
  }
  &.wide {
    box-shadow: none;
    .container {
      max-width: 100%;
      padding: 0 20px;
      @include m {
        padding: 0 24px;
      }
    }
  }
}
.container {
  display: flex;
  align-items: center;
  @include d {
    padding: 0 40px;
  }
  @include t {
    padding: 0 20px;
  }
  @include m {
    padding: 0 24px;
  }
}

.logo {
  margin-right: 32px;
  @include t {
    margin-right: 16px;
  }
  @include m {
    position: relative;
    z-index: 15;
    margin-right: auto;
  }
  img {
    width: 136px;
    @include t {
      width: 42px;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 32px;
  border-left: 1px solid $n6;
  @include x {
    padding-left: 0;
    border: none;
  }
  @include m {
    flex-grow: 0;
  }
  @include dark {
    border-color: $n3;
  }
  .theme {
    @include d {
      display: none;
    }
  }
  & > .btns {
    @include m {
      display: none;
    }
  }
}
.wrap {
  margin-right: auto;
  @include m {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 100px 0 32px;
    background: $n8;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
      background: $n1;
    }
    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
  & > .button {
    display: none;
    @include m {
      display: flex;
      margin: auto 24px 0;
    }
  }
  .btns {
    display: none;
    @include m {
      display: flex;
    }
  }
}
.nav {
  display: flex;
  @include m {
    flex-direction: column;
  }
}
.item,
.nav > div {
  &:not(:last-child) {
    margin-right: 40px;
    @include d {
      margin-right: 32px;
    }
    @include t {
      margin-right: 20px;
    }
    @include m {
      margin: 0;
    }
  }
}
.item {
  @include dm-sans;
  font-size: 14px;
  line-height: 40px;
  font-weight: 700;
  color: $n4;
  transition: all .2s;
  @include m {
    padding: 0 22px;
    border-left: 2px solid transparent;
    @include poppins;
    font-size: 24px;
    line-height: 64px;
    font-weight: 600;
  }
  &:hover,
  &.active {
    color: $n2;
    @include dark {
      color: $n8;
    }
    @include m {
      border-color: $p1;
    }
  }
}
.control {
  display: flex;
  align-items: center;
  .button {
    @include m {
      display: none;
    }
  }
}
.settings {
  margin-right: 32px;
  @include d {
    margin-right: 16px;
  }
  @include t {
    margin-right: 4px;
  }
}
.activity,
.notifications,
.control .button,
.theme {
  margin-right: 20px;
  @include d {
    margin-right: 16px;
  }
  @include t {
    margin-right: 8px;
  }
  @include m {
    margin-right: 12px;
  }
}
.activity {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  @include m {
    margin-right: 4px;
  }
  svg {
    fill: $n4;
    transition: fill .2s;
  }
  &:hover,
  &.active {
    svg {
      fill: $n2;
      @include dark {
        fill: $n8;
      }
    }
  }
}
.btns {
  display: flex;
  @include m {
    margin: auto 16px 0;
  }
  .button {
    @include m {
      flex: 0 0 calc(50% - 16px);
      width: calc(50% - 16px);
      margin: 0 8px;
    }
    &:not(:last-child) {
      margin-right: 8px;
      @include m {
        margin-right: 8px;
      }
    }
  }
}
.burger {
  display: none;
  @include m {
    display: block;
    position: relative;
    z-index: 15;
    margin-left: 12px;
    width: 32px;
    height: 32px;
    background: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &.active {
      &:before {
        transform: translateY(0) rotate(-45deg);
      }
      &:after {
        transform: translateY(0) rotate(45deg);
      }
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 16px;
      left: 6px;
      width: 20px;
      height: 2px;
      background: $n4;
      border-radius: 2px;
      transition: transform .2s;
    }
    &:before {
      transform: translateY(-4px);
    }
    &:after {
      transform: translateY(3px);
    }
  }
}